<template>
  <q-layout view="hHh LpR fff">
    <q-header v-if="authenticated" class="bg-white text-grey shadow" bordered>
      <q-toolbar class="constrain">
        <q-btn
          flat
          dense
          round
          @click="openDrawer"
          aria-label="Menu"
          icon="menu"
        />
        <q-toolbar-title
        v-if="authenticated"
        class="text-merriweather text-grey"
        >
        {{ $route.name }}
      </q-toolbar-title>
      <q-space/>
      <q-btn round class="q-mt-auto" unelevated>
        <q-avatar size="42px">
          <img src="https://cdn.quasar.dev/img/avatar2.jpg" />
        </q-avatar>
        <q-menu
          transition-show="jump-up"
          transition-hide="jump-down"
          :offset="[50, 10]"
        >
          <q-list style="min-width: 200px">
            <q-item clickable v-close-popup>
              <q-item-section side>
                <q-icon name="person" />
              </q-item-section>
              <q-item-section> Profil </q-item-section>
            </q-item>
            <q-item clickable>
              <q-item-section side>
                <q-icon name="admin_panel_settings" />
              </q-item-section>
              <q-item-section> Akun </q-item-section>
            </q-item>
            <q-separator />
            <q-item clickable @click="logout">
              <q-item-section side>
                <q-icon name="logout" />
              </q-item-section>
              <q-item-section> Logout </q-item-section>
            </q-item>
          </q-list>
        </q-menu>
      </q-btn>
      </q-toolbar>
    </q-header>
    <q-header v-else :class="visibleClass" class="bg-white text-grey">
      <q-toolbar class="constrain">
        <img src="./assets/logo.png" style="width: 80px" class="q-my-auto" />
        <q-toolbar-title></q-toolbar-title>
        <q-space />
        <q-tabs
          v-if="!authenticated"
          indicator-color="transparent"
          no-caps
          class="large-screen-only q-mt-sm"
        >
          <q-route-tab label="Beranda" to="/" />
          <q-route-tab label="Profil" />
          <q-route-tab label="Skema sertifikasi" />
          <q-route-tab label="Informasi" />
          <q-route-tab label="Berita" />
          <q-route-tab label="Galeri" />
        </q-tabs>
        
      </q-toolbar>
    </q-header>
    <div v-intersection="onIntersection" class="absolute-top"></div>
    <side-bar />
    <q-page-container >
     
      
      <router-view v-slot="{ Component }">
        <transition
          appear
          mode="out-in"
          enter-active-class="animate__animated animate__fadeIn animate__faster"
          leave-active-class="animate__animated animate__fadeOut animate__faster"
        >
          <component :is="Component" />
        </transition>
      </router-view>
    </q-page-container>
    <page-footer/>
  </q-layout>
</template>

<script>
import SideBar from "./components/templates/SideBar.vue";
import PageFooter from "./components/templates/PageFooter.vue";
import { mapGetters, mapActions } from "vuex";
import { computed, ref } from '@vue/runtime-core';
export default {
  name: "LayoutDefault",
  components: {
    SideBar,
    PageFooter,
  },
  setup() {
    const visible = ref(false)
    return {
      visible,
      visibleClass: computed(
        () => `${visible.value ? 'dark' : 'shadow-3'}`
      ),
      onIntersection (entry) {
        visible.value = entry.isIntersecting
      }
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  methods:{
    openDrawer(){
      this.$store.commit('kontrol/SET_DRAWER')
    },
    ...mapActions({
      logoutAction: "auth/logout",
    }),
    logout() {
      this.logoutAction().then(() => {
        this.$router.replace({
          name: "home",
        });
      });
    },
  }
};
</script>
<style lang="sass">
.page
  position: absolute
  top: 30px
  --animate-duration: 0.5s

</style>
