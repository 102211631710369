export default {
    namespaced: true,
    state: {
        url:'http://api4.sistelk.id/storage/',
        triger:0,
        drawer:false
    },
    getters: {
        triger(state){
            return state.triger
         },
        drawer(state){
            return state.drawer
         },
    },
    mutations: {
        SET_TRIGER(state){
            state.triger++
          },
        SET_DRAWER(state){
            state.drawer=!state.drawer
          },
    },
    actions: {
    }
}