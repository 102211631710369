<template>
  <q-drawer
    v-if="authenticated"
    v-model="leftDrawerOpen"
    overlay
    persistent
    class="bg-transparent"
    :width="250"
  >
    <q-list>
      <q-item-label header class="text-merriweather"
        >Hai, {{ user.user.name }}</q-item-label
      >
      <q-item clickable to="/dashboard" active-class="menu-link">
        <q-item-section avatar>
          <q-icon name="school" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Dashboard</q-item-label>
        </q-item-section>
      </q-item>
      <q-expansion-item
        icon="web"
        label="Landing page"
        class="hover-bg"
        @click="show = !show"
      >
        <transition
          appear
          mode="out-in"
          enter-active-class="animate__animated animate__bounceInLeft "
          leave-active-class="animate__animated animate__slideOutLeft animate__faster"
        >
          <q-item
            v-show="show"
            clickable
            to="/page/top-section"
            active-class="menu-link"
          >
            <q-item-section side>
              <q-icon name="fas fa-check-circle" size="xs" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Top Konten</q-item-label>
            </q-item-section>
          </q-item>
        </transition>
        <transition
          appear
          mode="out-in"
          enter-active-class="animate__animated animate__bounceInLeft animate__slow"
          leave-active-class="animate__animated animate__slideOutLeft animate__faster"
        >
          <q-item
            v-show="show"
            clickable
            
            active-class="menu-link"
          >
            <q-item-section side>
              <q-icon name="fas fa-check-circle" size="xs" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Profil LSP</q-item-label>
            </q-item-section>
          </q-item>
        </transition>
        <transition
          appear
          mode="out-in"
          enter-active-class="animate__animated animate__backInLeft"
          leave-active-class="animate__animated animate__slideOutLeft"
        >
          <q-item
            v-show="show"
            clickable
            to="/page/partner"
            active-class="menu-link"
          >
            <q-item-section side>
              <q-icon name="fas fa-check-circle" size="xs" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Partner</q-item-label>
            </q-item-section>
          </q-item>
        </transition>
        <transition
          appear
          mode="out-in"
          enter-active-class="animate__animated animate__bounceInLeft"
          leave-active-class="animate__animated animate__slideOutLeft"
        >
          <q-item
            v-show="show"
            clickable
           
            active-class="menu-link"
          >
            <q-item-section side>
              <q-icon name="fas fa-check-circle" size="xs" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Pengurus</q-item-label>
            </q-item-section>
          </q-item>
        </transition>
        <transition
          appear
          mode="out-in"
          enter-active-class="animate__animated animate__bounceInLeft"
          leave-active-class="animate__animated animate__slideOutLeft"
        >
          <q-item
            v-show="show"
            clickable
            to="/page/about"
            active-class="menu-link"
          >
            <q-item-section side>
              <q-icon name="fas fa-check-circle" size="xs" />
            </q-item-section>
            <q-item-section>
              <q-item-label>About Us</q-item-label>
            </q-item-section>
          </q-item>
        </transition>
        <transition
          appear
          mode="out-in"
          enter-active-class="animate__animated animate__bounceInLeft"
          leave-active-class="animate__animated animate__slideOutLeft"
        >
          <q-item
            v-show="show"
            clickable
            to="/page/visimisi"
            active-class="menu-link"
          >
            <q-item-section side>
              <q-icon name="fas fa-check-circle" size="xs" />
            </q-item-section>
            <q-item-section>
              <q-item-label>Visi & Misi</q-item-label>
            </q-item-section>
          </q-item>
        </transition>
      </q-expansion-item>
      <q-expansion-item
      icon="workspace_premium"
      label="Skema Sertifikasi"
      class="hover-bg"
      @click="show2 = !show2"
     >
     <transition
     appear
     mode="out-in"
     enter-active-class="animate__animated animate__bounceInLeft"
     leave-active-class="animate__animated animate__slideOutLeft"
   >
     <q-item
       v-show="show2"
       clickable
       to="/skema-sertifikasi"
       active-class="menu-link"
     >
       <q-item-section side>
         <q-icon name="fas fa-check-circle" size="xs" />
       </q-item-section>
       <q-item-section>
         <q-item-label>Skema</q-item-label>
       </q-item-section>
     </q-item>
   </transition>
      </q-expansion-item>
      <q-item clickable>
        <q-item-section avatar>
          <q-icon name="chat" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Asesor</q-item-label>
        </q-item-section>
      </q-item>
      <q-item
        clickable
        to="/berita"
      >
        <q-item-section avatar>
          <q-icon name="far fa-newspaper" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Berita</q-item-label>
        </q-item-section>
      </q-item>
    </q-list>
  </q-drawer>
</template>

<script>
import { ref } from "vue";
import { mapGetters, mapState } from "vuex";
export default {
  name: "SideBar",
  setup() {
    return {
      leftDrawerOpen: ref(false),
      show: ref(false),
      show2: ref(false),
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
    ...mapState("kontrol",["drawer"]),
  },
  watch:{
    drawer(){
      this.leftDrawerOpen=!this.leftDrawerOpen
    }
  },
  methods: {


  },
};
</script>
<style lang="sass">
img
  width: 80px
  margin-top: auto
</style>

