
import './styles/quasar.sass'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import '@quasar/extras/mdi-v4/mdi-v4.css'
import 'animate.css';
import Loading from 'quasar/src/plugins/Loading.js';import Notify from 'quasar/src/plugins/Notify.js';
// To be used on app.use(Quasar, { ... })
export default {
  config: {
    notify: { /* look at QuasarConfOptions from the API card */ },
    loading: { /* look at QuasarConfOptions from the API card */ }
  },
  plugins: {
    Notify,
    Loading,
  },
  animations: 'all',

}