import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard/DashView.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login'
        })
      }
      next();
    }
  },
  {
    path: '/page/top-section',
    name: 'Top Section',
    component: () => import('../views/landing/TopSection.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login'
        })
      }
      next();
    }
  },
  {
    path: '/page/partner',
    name: 'Partner',
    component: () => import('../views/landing/PartnerView.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login'
        })
      }
      next();
    }
  },
  {
    path: '/page/about',
    name: 'about',
    component: () => import('../views/landing/AboutView.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login'
        })
      }
      next();
    }
  },
  {
    path: '/page/visimisi',
    name: 'visimisi',
    component: () => import('../views/landing/VisiMisi.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login'
        })
      }
      next();
    }
  },
  {
    path: '/skema-sertifikasi',
    name: 'Skema Sertifikasi',
    component: () => import('../views/Home/SkemaSertifikasi.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login'
        })
      }
      next();
    }
  },
  {
    path: '/berita',
    name: 'Berita',
    component: () => import('../views/Home/BeritaView.vue'),
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/authenticated']) {
        return next({
          name: 'login'
        })
      }
      next();
    }
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home/HomeView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes

})

export default router
