<template>
  <q-footer class="bg-grey-10 text-white">
    <div v-if="!authenticated" class="row constrain text-grey large-screen-only" style="height:250px">
        <div class="col-12 col-sm-4 col-md-4-col-lg-4">
            <img src="../../assets/logo2.png" class="q-mt-lg"/>
            <p>
                Lembaga Sertifikasi Profesi (LSP P1) SMK Telkom Makassar,
                Start working with Tailwind CSS that can provide everything you need to generate awareness, drive traffic, connect.
            </p>
        </div>
        <div class="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4">
          
        </div>
    </div>
    <q-separator color="grey-9"/>
     <div class="row">
      <q-toolbar class="constrain text-grey text-caption">
        &copy;2023 lsp.smktelkom-mks.sch.id By. sistelk-project
    </q-toolbar>
     </div>
  </q-footer>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
name:'PageFooter',
computed:{
  ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
}
}
</script>

<style>

</style>